<template>
    <div id="contenedor" class="page-dia-habil-gerente">
      <v-container fluid class="white lighten-5" style="padding-left: 3%;padding-right: 3%;">                

        <div class="cont-shadow  bloq-height">
          <div class="d-flex align-items-center">
            <SearchCustomerSeller></SearchCustomerSeller>

            <div class="ml-auto">
                <a href="#"><img src="img/book-edit.svg"></a>
                <a href="#"><img src="img/notebook.svg"></a>
            </div>
          </div>
        </div>        
           
  
        <div class="cont-shadow py-2 d-flex justify-content-center align-items-center" style="background: rgb(245, 245, 245)">
          <div style="max-width:350px !important" class="mr-4">
            <span class="badge rounded-pill btn-custom p-2">Días hábiles tiempo de Ciclo de Venta</span>
          </div>

          <div>
            <v-radio-group v-model="optTrimOrMonth" :disabled="isLoading"  hide-details class="mt-0">
              <div class="d-flex align-items-center">
                <div class="mr-4">
                  <v-radio                                                 
                      label="Trimestral"
                      color="primary"
                      :value="1"      
                      hide-details                                             
                    ></v-radio>
                </div>                            
                <div>
                  <v-radio                                                          
                    label="Mensual"
                    color="primary"
                    :value="2" 
                    hide-details                   
                  ></v-radio> 
                </div>                            
              </div>
            </v-radio-group> 
          </div>         
        </div>
        
        <v-simple-table class="elevation-2 table-renglonaje my-t" > 
          <template v-slot:default>
            <thead class="table-heading cont-shadow" style="background: rgb(245, 245, 245)">
              <tr>
                <th class="text-center text-dark ">Gerencia</th>
                <th class="text-center text-dark " v-for="header, index in headerManager" :key="index">
                  {{header.text}}
                </th>                
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading">
                <td colspan="9" style="padding: 0 !important; height: 0 !important">
                  <v-progress-linear
                    indeterminate                    
                  ></v-progress-linear>                  
                </td>
              </tr>
              <tr v-else>
                <td class="text-center text-dark " style="min-width: 300px">
                    <table>
                        <tr>
                            <td>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon @click="participationDivision(asesores[0].KUGTE, 1)" v-bind="attrs"
                                        v-on="on" color="#B71C1C">mdi-chart-pie</v-icon> 
                                    </template>
                                    <span>Participación / Divisiones</span>
                                </v-tooltip>
                            </td>
                            <td>
                                <a>{{(asesores != '') ? asesores[0].GNAME : ''}}<br>
                                {{asesores[0].zona}}</a><br>
                                {{sumaNumHab}} h<br>
                                ${{promMonto | formatCurrency}} $/h
                            </td>
                            <td>                        
                                <div class="d-flex align-items-center">
                                  <router-link to="/planeador-manager">
                                    <v-icon color="#0069d9">
                                      mdi mdi-calendar-check-outline
                                    </v-icon>
                                  </router-link>
                                  <img src="img/g-maps.svg" class="mr-1"/>
                                  <!-- <img src="img/car-front-fill.svg" />&nbsp; -->
                                  
                                  <v-col cols="12" class="text-body-2 mt-n4">
                                    <div class="d-flex justify-center mr-2 ml-2">
                                      <sales-policy-file-downloader class="mx-2" policy-type="delivery" />
                                      <sales-policy-file-downloader class="mx-2" policy-type="sellCredit" />
                                    </div>
                                  </v-col>
                                </div>
                            </td>
                        </tr>
                    </table>
                </td>
                <td class="text-center text-dark " v-for="gerente, index in gerentes" :key="index">
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="mr-2">
                        <p class="mb-0"><span style="color:red">[{{gerente['clienteNuevoTresMeses']}}]</span></p>
                        <p class="mb-0"><span style="color:blue">[{{gerente['clienteNuevo']}}]</span></p>
                      </div>
                      <div>{{formatDecimal(gerente['periodos'])}}</div>
                    </div>                    
                </td>                
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        

        <div class="cont-shadow bloq-height">
          <div class="row">
            <div class="col-xs-12 col-md-4 col-lg-2">
              <div class="d-flex align-items-center">                           
                  <label class="filter-text">Agrupar por:</label>
                  <v-select
                    :items="['Cliente', 'Zona']"
                      v-model="agruparPor"
                      hide-details                                 
                      small
                      dense
                      solo 
                      class="ml-2" 
                      :disabled="isLoading"                         
                  ></v-select>
              </div>
            </div> 

            <div class="col-md-4 col-12 col-lg-2">
              <div class="d-flex align-items-center">                           
                  <label class="filter-text">Periodo:</label>
                  <v-select
                      :items="months"
                      item-text="mes"
                      item-value="numero"
                      v-model="periodoMes"
                      hide-details                                 
                      small
                      dense
                      solo
                      placeholder="Mes"  
                      class="ml-2"  
                      :disabled="isLoading"                                   
                  ></v-select>

                  <v-select
                    :items="listAnios"
                      v-model="periodoAnio"
                      hide-details                                 
                      small
                      dense
                      solo
                      placeholder="Año"
                      class="ml-2"  
                      :disabled="isLoading"                                      
                  ></v-select>
              </div>
            </div> 

            <div class="col-md-4 col-lg-8 d-flex align-items-center justify-content-end">
              <v-checkbox
                v-model="showColums"
                :label="`Mostrar más ${optTrimOrMonth == 1 ? 'trimestres' : 'meses'}`"
                hide-details
                class="mt-0"
              ></v-checkbox>
            </div>
          </div>
        </div>

        <!-- table asesores   -->
        <v-data-table v-if="agruparPor == 'Zona'"    
            :headers="headersAsesores.slice(0, showColums ? 9 : 5)"
            :items="asesores"
            :footer-props="footerProps"
            :options="dataTableOptions"  
            :sort-by="sortBy"
            :sort-desc="sortDesc"    
            :mobile-breakpoint="null"   
            :loading="isLoading"        
            class="elevation-2 table-renglonaje tabla-zebra table-seller"> 

        <template v-slot:[`item.zona`]="{ item }">
          <div class="d-flex align-items-center justify-content-start" style="max-width:600px">

            <div>
              <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="participationDivision(item.PERNR, 2)" v-bind="attrs"
                        v-on="on" color="#B71C1C">mdi-chart-pie</v-icon> 
                    </template>
                    <span>Participación / Divisiones</span>
                  </v-tooltip>
              </div>   

                <div v-if="item.horariosPedidos.porcenthrLaboral >= item.horariosPedidos.porcenthrNoLaboral">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">                      
                        <img 
                          v-bind="attrs"
                          v-on="on"
                          src="img/brightness-high-fill.svg" />
                    </template>
                    <span>Horario Laboral: {{item.horariosPedidos.porcenthrLaboral | formatCurrency}}%</span>
                  </v-tooltip>
                  
                </div>
                <div v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">                      
                        <img 
                          v-bind="attrs"
                          v-on="on"
                          src="img/weather-night.svg" />
                    </template>                    
                    <span>Horario No Laboral: {{item.horariosPedidos.porcenthrNoLaboral | formatCurrency}}%</span>
                  </v-tooltip>
                </div>
            </div>

            <div class="ml-4">
              <div>
                <a style="text-decoration: underline;font-weight: bold;">{{ item.PERNR }}</a>
              </div>
              <div>
                <img src="img/car-front-fill.svg" />
              </div>
            </div>

            <div class="ml-4 p-1">
              <div :class="['circle', getColor(item.trafficlight)]"></div>
            </div>

            <div class="ml-4 text-left">
              <div style="min-width: 200px">
                <span>{{item.SNAME}}</span>
              </div>
              <div>
                <p class="mb-0"><b>{{item.zona}}</b></p>
              </div>
            </div>

            <div class="ml-auto text-right">
              <p class="mb-0"><b>{{item.numHabitantes}} h</b></p>
              <p class="mb-0"><b>{{item.promHabitantes | formatCurrency}} $/h</b></p>
            </div>

          </div>
        </template>
        <template v-slot:[`item.col1`]="{ item }">
          <div class="d-flex align-items-center cont-ped">
              <div class="mr-2">
                <p class="mb-0"><span style="color:red">[{{item.periodos[0].clienteNuevoTresMeses}}]</span></p>
                <p class="mb-0"><span style="color:blue">[{{item.periodos[0].clienteNuevo}}]</span></p>
              </div>
              <div>{{formatDecimal(item.periodos[0].prom)}}</div>
          </div> 
        </template>
        <template v-slot:[`item.col2`]="{ item }">
          <div class="d-flex align-items-center cont-ped">
              <div class="mr-2">
                <p class="mb-0"><span style="color:red">[{{item.periodos[1].clienteNuevoTresMeses}}]</span></p>
                <p class="mb-0"><span style="color:blue">[{{item.periodos[1].clienteNuevo}}]</span></p>
              </div>
              <div>{{formatDecimal(item.periodos[1].prom)}}</div>
          </div>           
        </template>
        <template v-slot:[`item.col3`]="{ item }">
          <div class="d-flex align-items-center cont-ped">
              <div class="mr-2">
                <p class="mb-0"><span style="color:red">[{{item.periodos[2].clienteNuevoTresMeses}}]</span></p>
                <p class="mb-0"><span style="color:blue">[{{item.periodos[2].clienteNuevo}}]</span></p>
              </div>
              <div>{{formatDecimal(item.periodos[2].prom)}}</div>
          </div> 
        </template>
        <template v-slot:[`item.col4`]="{ item }">
          <div class="d-flex align-items-center cont-ped">
              <div class="mr-2">
                <p class="mb-0"><span style="color:red">[{{item.periodos[3].clienteNuevoTresMeses}}]</span></p>
                <p class="mb-0"><span style="color:blue">[{{item.periodos[3].clienteNuevo}}]</span></p>
              </div>
              <div>{{formatDecimal(item.periodos[3].prom)}}</div>
          </div> 
        </template>
        <template v-slot:[`item.col5`]="{ item }">
          <div class="d-flex align-items-center cont-ped">
              <div class="mr-2">
                <p class="mb-0"><span style="color:red">[{{item.periodos[4].clienteNuevoTresMeses}}]</span></p>
                <p class="mb-0"><span style="color:blue">[{{item.periodos[4].clienteNuevo}}]</span></p>
              </div>
              <div>{{formatDecimal(item.periodos[4].prom)}}</div>
          </div> 
        </template>
        <template v-slot:[`item.col6`]="{ item }">
          <div class="d-flex align-items-center cont-ped">
              <div class="mr-2">
                <p class="mb-0"><span style="color:red">[{{item.periodos[5].clienteNuevoTresMeses}}]</span></p>
                <p class="mb-0"><span style="color:blue">[{{item.periodos[5].clienteNuevo}}]</span></p>
              </div>
              <div>{{formatDecimal(item.periodos[5].prom)}}</div>
          </div> 
        </template>
        <template v-slot:[`item.col7`]="{ item }">
          <div class="d-flex align-items-center cont-ped">
              <div class="mr-2">
                <p class="mb-0"><span style="color:red">[{{item.periodos[6].clienteNuevoTresMeses}}]</span></p>
                <p class="mb-0"><span style="color:blue">[{{item.periodos[6].clienteNuevo}}]</span></p>
              </div>
              <div>{{formatDecimal(item.periodos[6].prom)}}</div>
          </div> 
        </template>
        <template v-slot:[`item.col8`]="{ item }">
          <div class="d-flex align-items-center cont-ped">
              <div class="mr-2">
                <p class="mb-0"><span style="color:red">[{{item.periodos[7].clienteNuevoTresMeses}}]</span></p>
                <p class="mb-0"><span style="color:blue">[{{item.periodos[7].clienteNuevo}}]</span></p>
              </div>
              <div>{{formatDecimal(item.periodos[7].prom)}}</div>
          </div> 
        </template>

        <template v-slot:footer>
          <!-- Table Rumen Grupo -->
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">         
                <v-simple-table class="my-t table-renglonaje elevation-2">
                  <template v-slot:default>
                    <thead class="h-title">
                      <tr>
                        <th colspan="5"></th>
                        <th class="text-center p-0 m-0 pt-1" style="height: 30px; color: red;">Proyección</th>
                        <th class="text-center p-0 m-0 pt-1" style="height: 30px" colspan="6">Facturación neta por trimestre (miles)</th>
                      </tr>
                      <tr>                      
                        <th class="text-center p-1">Gerencia</th>
                        <th class="text-center p-1">N° de grupos</th>
                        <th class="text-center p-1">Pedidos totales del mes (miles)</th>
                        <th class="text-center p-1">Crec. Acum. del Grupo</th>
                        <th class="text-center p-1">Crec. 3 vs 3 del Grupo</th>
                        <th class="text-center p-1" v-for="tr in trimGrupo" :key="tr.value">
                          {{ tr.date }}                      
                        </th>
                      </tr>
                    </thead>
                    <tbody class="sin-border">
                      <tr v-if="isLoadingGroup">
                        <td colspan="9" style="padding: 0 !important; height: 0 !important">
                          <v-progress-linear
                            indeterminate                    
                          ></v-progress-linear>                  
                        </td>
                      </tr>
                      <tr v-else>
                        <td>Gerencia <br>{{grupo.GEREN}}</td>
                        <td>{{grupo.GPOS}}</td>
                        <td>${{grupo.PEDTOT | formatCurrency}}</td>
                        <td>{{grupo.CRECC | formatCurrency}}%</td>
                        <td>{{grupo.CREC3 | formatCurrency}}%</td>
                        <td>${{grupo.PROYEC | formatCurrencyMiles}}</td>
                        <td>${{grupo.FACTRIM | formatCurrencyMiles}}</td> 
                        <td>${{grupo.FACTRIM1 | formatCurrencyMiles}}</td>     
                        <td>${{grupo.FACTRIM2 | formatCurrencyMiles}}</td>     
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </v-col>
          </v-row>
          <!--end Table Rumen Grupo -->
        </template>

        </v-data-table>
        <!-- end table asesores -->

        <!-- table clientes --><v-data-table  
            v-if="agruparPor == 'Cliente'"                  
            :headers="headersAsesores.slice(0, showColums ? 9 : 5)"
            :items="clientesFiltrados"
            :footer-props="footerProps"
            :options="dataTableOptions"  
            :sort-by="sortBy"
            :sort-desc="sortDesc"    
            :mobile-breakpoint="null"   
            :loading="isLoading"        
            class="elevation-2 table-renglonaje tabla-zebra">

            <template v-slot:header>
              <thead style="border-bottom: none !important">              
                <tr style="box-shadow: none !important">
                  <th class="text-center py-2">
                    <input class="form-check-input" type="checkbox" value="" id="checkFacturas" v-model="filtrarPorFactVencidas"> 
                    <label class="form-check-label ml-2" for="checkFacturas">Con facturas vencidas a más de 40 días</label>
                  </th>
                  <th :colspan="showColums ? 8 : 4"></th>
                </tr> 
              </thead>
          </template>

        <template v-slot:[`item.zona`]="{ item }">
          <div class="d-flex align-items-center justify-content-start" style="max-width:600px">
            <div>
              <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="participationDivision(item.KUNAG, 3)" v-bind="attrs"
                        v-on="on" color="#B71C1C">mdi-chart-pie</v-icon> 
                    </template>
                    <span>Participación / Divisiones</span>
                  </v-tooltip>
              </div>
            </div>

            <div class="ml-4">
              <div>
                <a style="text-decoration: underline;font-weight: bold;">{{ item.KUNAG }}</a>
              </div>
            </div>

            <div class="ml-4 text-left">
              <div style="min-width: 200px">
                <span>{{item.NAME1}}</span>
              </div>
              <div>
                <p class="mb-0"><b>{{item.zona}}</b></p>
              </div>
            </div>

            <div class="ml-auto text-right">
              <v-icon :style="{ color: item.clientesNuevos === true ? 'blue !important' : (item.clienteNuevoTresMeses === true ? 'red !important' : '') }">
                mdi-account-tie
              </v-icon>
            </div>

          </div>

        </template>
        <template v-slot:[`item.col1`]="{ item }">
          {{ formatDecimal(item.periodos[0].prom) }}          
        </template>
        <template v-slot:[`item.col2`]="{ item }">
          {{ formatDecimal(item.periodos[1].prom) }}
        </template>
        <template v-slot:[`item.col3`]="{ item }">
          {{ formatDecimal(item.periodos[2].prom) }}
        </template>
        <template v-slot:[`item.col4`]="{ item }">
          {{ formatDecimal(item.periodos[3].prom) }}
        </template>
        <template v-slot:[`item.col5`]="{ item }">
          {{ formatDecimal(item.periodos[4].prom) }}
        </template>
        <template v-slot:[`item.col6`]="{ item }">
          {{ formatDecimal(item.periodos[5].prom) }}
        </template>
        <template v-slot:[`item.col7`]="{ item }">
          {{ formatDecimal(item.periodos[6].prom) }}
        </template>
        <template v-slot:[`item.col8`]="{ item }">
          {{ formatDecimal(item.periodos[7].prom) }}
        </template>

        <template v-slot:footer>
          <!-- Table Rumen Grupo -->
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">         
                <v-simple-table class="my-t table-renglonaje elevation-2">
                  <template v-slot:default>
                    <thead class="h-title">
                      <tr>
                        <th colspan="5"></th>
                        <th class="text-center p-0 m-0 pt-1" style="height: 30px; color: red;">Proyección</th>
                        <th class="text-center p-0 m-0 pt-1" style="height: 30px" colspan="6">Facturación neta por trimestre (miles)</th>
                      </tr>
                      <tr>                      
                        <th class="text-center p-1">Gerencia</th>
                        <th class="text-center p-1">N° de grupos</th>
                        <th class="text-center p-1">Pedidos totales del mes (miles)</th>
                        <th class="text-center p-1">Crec. Acum. del Grupo</th>
                        <th class="text-center p-1">Crec. 3 vs 3 del Grupo</th>
                        <th class="text-center p-1" v-for="tr in trimGrupo" :key="tr.value">
                          {{ tr.date }}                      
                        </th>
                      </tr>
                    </thead>
                    <tbody class="sin-border">
                      <tr v-if="isLoadingGroup">
                        <td colspan="9" style="padding: 0 !important; height: 0 !important">
                          <v-progress-linear
                            indeterminate                    
                          ></v-progress-linear>                  
                        </td>
                      </tr>
                      <tr v-else>
                        <td>Gerencia <br>{{grupo.GEREN}}</td>
                        <td>{{grupo.GPOS}}</td>
                        <td>${{grupo.PEDTOT | formatCurrency}}</td>
                        <td>{{grupo.CRECC | formatCurrency}}%</td>
                        <td>{{grupo.CREC3 | formatCurrency}}%</td>
                        <td>${{grupo.PROYEC | formatCurrencyMiles}}</td>
                        <td>${{grupo.FACTRIM | formatCurrencyMiles}}</td> 
                        <td>${{grupo.FACTRIM1 | formatCurrencyMiles}}</td>     
                        <td>${{grupo.FACTRIM2 | formatCurrencyMiles}}</td>     
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </v-col>
          </v-row>
          <!--end Table Rumen Grupo -->
        </template>

        </v-data-table>
        <!-- end atble clientes -->
       

        <v-row class="mt-4">
            <v-col style="text-align: left;">
                <p class="mb-0" style="color:blue">[Clientes nuevos]</p>
                <p class="mb-0" style="color:red">[Clientes nuevos activos de los últimos 2 meses y mes actual]</p>
                <p class="mb-0" style="color: black">*Días hábiles</p>
            </v-col>
        </v-row>        

        <!-- modal participación / divisiones -->
        <v-dialog
        v-model="dialogParticipationDivisionShow"
        transition="dialog-bottom-transition" max-width="1024">
        <v-card class="pa-3" >
          <div class="text-center pa-1 pl-3" style="background-color: #000; color:#fff">
            Participación / Divisiones - {{tipoDivision == 1 ? 'Gerente' : tipoDivision == 2 ? 'Detalle zona' : 'Detalle cliente'}}
          </div>

          <div class="row">
            <div class="col">
              <v-simple-table dense>
                <thead>
                  <tr class="custom-table-bordered">
                    <th class="text-center text-dark" style="color:#00B99D !important;">Facturación Bruta</th>
                    <th class="text-center text-dark" style="color:#FF9F00 !important;">% de participación</th>
                    <th class="text-center text-dark" style="color:#746EE5 !important;">División</th>
                  </tr>
                </thead>
                <tbody class="custom-table-bordered">
                  <template v-if="loadingParticipationDivision">
                    <tr>
                      <td colspan="3">
                        <v-progress-linear
                          indeterminate
                          color="cyan"
                        ></v-progress-linear><br>
                        Cargando... Por favor espere
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="dpd in dataParticipationDivision" :key="dpd.DIVI">
                      <td class="font-weight-bold text-body-2"><b>${{ dpd.FACNETA | formatCurrency }}</b></td>
                      <td class="font-weight-bold text-body-2"><b>%{{ dpd.PORPART | formatCurrency}}</b></td>
                      <td class="font-weight-bold text-body-2"><b>{{ dpd.DIVI }}</b></td>
                    </tr>
                  </template>
                </tbody>
              </v-simple-table><br>
              <pie-chart v-if="!loadingParticipationDivision" :chartdata="chartdata4" :options="chartOptions" />
            </div>
            <div class="col trimestre-client-group">
              <span>Trimestre:</span>
                              
                  <v-select
                    :items="trimestres"
                    item-text="date"
                    v-model="selectTrimestre"
                    @change="actualizarSelectTrimestre()"
                    outlined
                  ></v-select>
                
            </div>            
          </div>
          <v-card-actions>
            <v-btn color="primary" text @click="dialogParticipationDivisionShow = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- table Folio -->
      <div class="mt-5">
        <v-data-table         
            :headers="headersFolios"
            :items="itemsFolios"
            :footer-props="footerProps"
            :options="dataTableOptions" 
            :mobile-breakpoint="null"
            class="elevation-2 table-renglonaje">            
        </v-data-table>
      </div>
        
        <LegacyFooter></LegacyFooter>
      </v-container>
    </div>
  </template>
    
  <script>
    import axios from "@/plugins/axios";
    import { mapGetters, mapActions } from "vuex";
    import Service from "@/mixins/service.js"
    import LegacyFooter from "@/views/seller/components/LegacyFooter.vue"
    import PieChart from "./components/PieChart.vue";
    import moment from 'moment';
    import SearchCustomerSeller from '@/components/SearchCustomerSeller.vue';
    import SalesPolicyFileDownloader from '@/components/policies/SalesPolicyFileDownload.vue'
    
    export default {
      name: "DayCustomerDelivery",
      components: {LegacyFooter, PieChart, SearchCustomerSeller, SalesPolicyFileDownloader},
      props: [],
      mixins: [Service],
      data() {
        return {  
          headersFolios: [],
          itemsFolios: [],          
          clientes:[], 
          asesores: [], 
          gerentes: [], 
          pedidos: [],       
          months: [
            { mes: 'Enero', numero: 1 },
            { mes: 'Febrero', numero: 2 },
            { mes: 'Marzo', numero: 3 },
            { mes: 'Abril', numero: 4 },
            { mes: 'Mayo', numero: 5 },
            { mes: 'Junio', numero: 6 },
            { mes: 'Julio', numero: 7 },
            { mes: 'Agosto', numero: 8 },
            { mes: 'Septiembre', numero: 9 },
            { mes: 'Octubre', numero: 10 },
            { mes: 'Noviembre', numero: 11 },
            { mes: 'Diciembre', numero: 12 },
          ], 
          headersAsesoresDefaul: [{ text: 'Zona', value: 'zona', align:"center", sortable: false }],         
          headersAsesores: [],      
          headerManager: [],
          headerPedidos: [],     
          footerProps: {
              itemsPerPageOptions: [10, 14, 24, 34, { text: 'Todas', value: -1 }],
              itemsPerPageText: 'Mostrar en grupo de:',
              showCurrentPage: false,
              pageText: 'Desplegado {0} / {1} de {2}'           
          }, 
          dataTableOptions: {
              itemsPerPage: 24,
              pagination: {
                  enabled: true,
                  options: {
                      numeric: true,
                  },
              },
          },
          sortBy: '',
          sortDesc: false, 
          dialogParticipationDivisionShow: false,
          loadingParticipationDivision: false,
          dataParticipationDivision: {},
          trimestres: [],
          selectTrimestre: {},
          chartdata4: null,
          isLoading: true,
          isLoadingGroup: true,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false
          },          
          agruparPor: 'Zona',
          periodoMes: moment().month() + 1,
          periodoAnio: moment().year(),
          periodTrim: '',
          listAnios: [],
          optTrimOrMonth: 1,
          sumaNumHab: 0,
          promMonto: 0,
          code: {},
          showColums: false,
          grupo: {},
          trimGrupo: [],
          tipoDivision: 0,
          filtrarPorFactVencidas: false
        };
      },    
      mounted() {
        this.getGrupos()
        this.trimGrupo = this.calcularUltimosTrim(4, this.periodoMes)
        this.trimestres = this.calcularUltimosTrim(3, this.periodoMes) //inicia desde el trimestre anterior del actual        
        this.selectTrimestre = this.trimestres[0]
        this.listAnios = this.generarListaDeAnios(7)
        this.calcularPeriodoTrim()
        this.generarHeaderFolio()
        this.generarHeaderTrimestral()
        this.getData() 
      },    
      computed: {
        ...mapGetters({
          authenticated: "auth/authenticated",
          user: "auth/user",
        }),
        clientesFiltrados() {
          if (this.filtrarPorFactVencidas) {
            return this.clientes.filter(objeto => objeto.factVencidas.length > 0);
          } else {
            return this.clientes;
          }
        }             
      }, 
      watch:{
        periodoMes(){
          if (this.periodoMes != '' && this.periodoAnio){
            if(this.optTrimOrMonth == 1){              
              this.generarHeaderTrimestral()
            }else if(this.optTrimOrMonth == 2){
              this.generarHeaderMensual()
            }
            this.getData()
          }
        },
        periodoAnio(){
          if (this.periodoMes != '' && this.periodoAnio){   
            if(this.optTrimOrMonth == 1){              
              this.generarHeaderTrimestral()
            }else if(this.optTrimOrMonth == 2){
              this.generarHeaderMensual()
            }  
            this.getData()
          }
        },
        optTrimOrMonth(value){
          if(value == 1){            
            this.generarHeaderTrimestral()
          }else if(value == 2){
            this.generarHeaderMensual()
          }
          this.getData()
        },
        agruparPor(){
          if(this.agruparPor == 'Zona'){
            this.headersAsesoresDefaul[0].text = 'Zona'
          }else{
            this.headersAsesoresDefaul[0].text = 'Cliente'
          }
        }
      },    
      methods: { 
        ...mapActions({
          sendToPDF: 'printer/sendToPDF'
        }),       
        async getData(){
          this.isLoading = true
          this.asesores = []
          this.clientes = []
          this.gerentes = []
          this.calcularPeriodoTrim()
          let response = await axios.get('/dayCustomerDelivery',{
            params:{
              manager_id: '0' + this.user.employee_number,
              trim: this.optTrimOrMonth == 1 ? this.periodTrim : '',
              month: this.optTrimOrMonth == 2 ? this.periodoMes : '',
              year: this.periodoAnio,
              VKORG: this.user.VKORG,
              VTWEG: this.user.VTWEG
            }
          })

          //Asesores
          this.asesores = response.data.data

          //Obtener todos los clientes de cada asesor
          const allClientes = this.asesores.flatMap(asesor => asesor.CLIENTES);
          this.clientes.push(...allClientes);
          
          //Suma de promedios por periodos de cada asesor
          const sumasPorPeriodo = {};
          this.asesores.forEach((asesor) => {
              asesor.periodos.forEach((periodo) => {
                  const clavePeriodo = periodo.period                 

                  // Inicializar si no existe
                  if (!sumasPorPeriodo[clavePeriodo]) {
                      sumasPorPeriodo[clavePeriodo] = {
                          periodos: 0,
                          clienteNuevo: 0,
                          clienteNuevoTresMeses: 0,
                          sumPedidos : 0,
                          sumDias : 0
                      };
                  }

                  // Sumar valores
                  sumasPorPeriodo[clavePeriodo].sumPedidos += periodo.numPedidos
                  sumasPorPeriodo[clavePeriodo].sumDias += periodo.sumDiffDays            
                  sumasPorPeriodo[clavePeriodo].clienteNuevo += periodo.clienteNuevo            
                  sumasPorPeriodo[clavePeriodo].clienteNuevoTresMeses += periodo.clienteNuevoTresMeses    
              });
          });

          console.log(sumasPorPeriodo)

          // Iterar sobre el resultado de sumasPorPeriodo y realizar la operación
          const newArray = []
          const numAsesores = this.asesores.length
          for (const clavePeriodo in sumasPorPeriodo) {
             if (Object.hasOwnProperty.call(sumasPorPeriodo, clavePeriodo)) {
              const periodo = sumasPorPeriodo[clavePeriodo];

              // Verificar que sumPedidos no sea 0 para evitar divisiones por 0
              let promPeriodo = periodo.sumPedidos != 0 ? periodo.sumDias / periodo.sumPedidos : 0
              let promClienteNuevo = periodo.clienteNuevo != 0 ? periodo.clienteNuevo / numAsesores : 0
              let promclienteNuevoTresMeses = periodo.clienteNuevoTresMeses != 0 ? periodo.clienteNuevoTresMeses / numAsesores : 0
              newArray.push({
                periodos: promPeriodo,
                clienteNuevo: promClienteNuevo,
                clienteNuevoTresMeses: promclienteNuevoTresMeses,
              })
            }
          }

          this.gerentes = newArray;


          //Promedio Numero de habitantes
          this.sumaNumHab = this.asesores.reduce((suma, asesor) => suma + asesor.numHabitantes, 0);          
          this.promMonto = this.asesores.reduce((suma, asesor) => suma + asesor.promHabitantes, 0);          
          this.isLoading = false;
          this.sendToPDF({active: true, height: '1080'});
        },
        async participationDivision(code, opc){ 
          this.tipoDivision = opc
          this.code = {code: code,opc: opc}
          try{         
              this.dialogParticipationDivisionShow=true;
              this.loadingParticipationDivision = true;

              let ruta
              if(opc == 1){
                ruta = 'participacion-divisiones-gte'
              }else if(opc == 2){
                ruta = 'participacion-divisiones-asr'
              }else if(opc == 3){
                ruta = 'participacion-divisiones-cte'
              }

              let response = await axios.get('/dayCustomerDelivery/' + ruta, {
                  params:{
                    CODE: code,
                    VKORG: this.user.VKORG,
                    VTWEG: this.user.VTWEG,
                    P_TRIM:this.selectTrimestre.trim,
                    P_YEAR:this.selectTrimestre.year
                  }
              })            
                  
              this.dataParticipationDivision = response.data.data;

              if(this.dataParticipationDivision.length > 0){       
                let colors = ["#FF9F00","#746EE5", "#00B99D"];              
                let label = []             
                let data = []             
                let background = []             

                this.dataParticipationDivision.forEach(function(value, index){                
                    const letters = '0123456789ABCDEF';
                    let color = '#';
                    if(index > 2){
                        for (let i = 0; i < 6; i++) {
                          color += letters[Math.floor(Math.random() * 16)];
                        }
                        colors.push( color ); 
                    }

                    label.push(value.DIVI)
                    data.push(value.PORPART)
                    background.push(colors[index])
                });

                this.chartdata4 = {
                  labels: label,
                  datasets: [
                    {
                      backgroundColor: background,
                      data: data,
                      fill: false,
                      tension: 0.1,
                    },
                  ],
                };
              }
              this.loadingParticipationDivision = false;
          }catch(error){
              console.error(error);
          }
        }, 
        async getGrupos(){
          try{
            let response = await axios.get('/dayCustomerDelivery/grupos', {
                                        params:{
                                          USERID: '0' + this.user.employee_number,
                                          VTWEG: this.user.VTWEG,
                                        }
                                    })    
            this.grupo = response.data.data
            this.isLoadingGroup = false
          }catch(error){
              console.error(error);
          }
        },      
        formatDecimal(dato){
          return Number(dato.toFixed(2));
        },
        getColor(dato){
          const color = {
            1: 'red',
            2: 'yellow',
            3: 'green'
          }

          return color[dato]
        },
        generarDocumento(){

        },
        actualizarSelectTrimestre(){
          this.selectTrimestre = this.trimestres.find(trimestre => trimestre.date === this.selectTrimestre);
          this.participationDivision(this.code.code, this.code.opc)
        },
        calcularPeriodoTrim(){
          const fecha = moment().month(this.periodoMes - 1); // Restamos 1 porque los meses en Moment.js son indexados desde 0
          this.periodTrim = fecha.quarter();
        },
        generarHeaderFolio(){
          this.headersFolios  = [
            { text: 'Folio', value: 'folio', align: "center" },
            { text: 'Ruta', value: 'folio', align: "center" },
            { text: 'Cliente', value: 'folio', align: "center" },
            { text: 'Fecha reporte', value: 'folio', align: "center" },
            { text: 'Fecha incidente', value: 'folio', align: "center" },
            { text: 'Pedido', value: 'folio', align: "center" },
            { text: 'Factura', value: 'folio', align: "center" },
            { text: 'Anomalía', value: 'folio', align: "center" },
          ]
        },
        generarHeaderTrimestral(){
          this.headersAsesores = this.headersAsesoresDefaul 
          
          let fechasTrimestres = this.calcularUltimosTrim(8, this.periodoMes)          
          let fechas = []
          fechasTrimestres.forEach(function(value, index){
            let objTrimestral = { text: `Trim ${value.date}`, value: `col${index+1}`, align: "center" }
            fechas.push(objTrimestral)
          })
          this.headersAsesores = [...this.headersAsesores, ...fechas]
          
          this.headerManager = fechas
        },
        generarHeaderMensual(){
          this.headersAsesores = this.headersAsesoresDefaul
         
          let fechasMensual = this.calcularUltimosMeses(8, this.periodoMes)
          let fechas = []
          fechasMensual.forEach(function(value, index){
            let objMensual = { text: `${value.date}`, value: `col${index+1}`, align: "center" }
            fechas.push(objMensual)
          })
          this.headersAsesores = [...this.headersAsesores, ...fechas]
          
          this.headerManager = fechas
        }        
      }
    }
    </script>

    <style>
      .page-dia-habil-gerente .tabla-zebra tr:nth-child(even) {
        background-color: #f0f0f0; 
      }
      .page-dia-habil-gerente td{
          padding: 6px 5px !important;
      }

      .page-dia-habil-gerente thead th{
        min-width: 5% !important;
        padding: 0 !important;
      }
    </style>
    
  <style scoped>    
    .btn-danger{
          background-color:red !important;
          color:white !important;
      }
    .filter-text{
      font-size: 14px;
    }
    .btn-custom{
      background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
          opacity: 1;
          color: #FFF !important;
          text-transform: inherit !important;
          font-size: 12px !important;

    } 
    .text-center{
      text-align:center;
    }
    .pagination-right {
      justify-content: flex-end;
    }
    .circle{
      width: 11px;
      height: 11px;
      border-radius: 50%;
    }
    .red{ background: red;}
    .yellow{ background: yellow;}
    .green{ background: green;}
    .cont-shadow{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
    }
    .bloq-height{
      flex: 1;
      padding: 8px 15px;
    }
    .tabla-zebra tr:nth-child(even) {
      background-color: #f0f0f0; /* Color de fondo para filas pares (gris en este caso) */
    }
    .h-title{
        background: #F5F5F5 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
        opacity: 1;
        border-bottom: 2px solid #707070 !important;
        font-weight: 500;
        font-family: Roboto !important;
        padding: 12px !important;
        font-size: 14px !important;
    }
    .h-title tr{        
        box-shadow: none !important;    
        background: transparent !important   
    }
    .cont-ped{
      width: 50px;
      margin: 0 auto
    }
  </style>